import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, ListItemText, Button } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';
import { useTour } from '@reactour/tour';
// mock
// import account from '../../../../_mock/account';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// components
import Logo from '../../../../components/logo';
import Scrollbar from '../../../../components/scrollbar';
// import NavSection from '../../../../components/nav-section';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import WebinarNavButton from '../../../../components/nav-section/WebinarNavButton';
import HelpNavButton from '../../../../components/nav-section/HelpNavButton';
import { StyledNavItem, StyledNavItemIcon } from '../../../../components/nav-section/styles';
import SvgColor from '../../../../components/svg-color/SvgColor';
import Iconify from '../../../../components/iconify/Iconify';
import CommonCircularProgress from '../../../../components/circular-progress/CommonCircularProgress';

// import navConfig from './config';
import { defaultError, informaGreen, userRouteName } from '../../../../utils/constants';
import apiMiddleWare from '../../../../utils/useApiMiddleWare';
import customToast from '../../../../utils/customToast';

// ----------------------------------------------------------------------

const NAV_WIDTH = 250;

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));
const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// ----------------------------------------------------------------------

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { setIsOpen, setCurrentStep, currentStep } = useTour();

    const isDesktop = useResponsive('up', 'lg');

    const accountData = useLocalStorage('sessionInfo')?.json;
    const account = {
        displayName:
            accountData?.firstName && accountData?.lastName
                ? `${accountData?.firstName} ${accountData?.lastName}`
                : `${accountData?.profile?.fullName}`,
        email: accountData?.email,
        role: accountData?.role,
        showcaseAccepted: accountData?.showcaseAccepted,
        photoURL: '/assets/images/avatars/avatar_default.jpg',
    };

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleClose = async (value = null) => {
        try {
            if (value === 'logout') {
                const res = await apiMiddleWare({ url: '/users/logout', type: 'post' });
                if (!res?.data?.ok) {
                    customToast({
                        msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
                        type: 'error',
                    });
                    return;
                }
                secureLocalStorage.removeItem('showcaseID');
                secureLocalStorage.setItem('sessionInfo', {
                    roles: [],
                    json: {},
                    xAuth: '',
                });
                navigate('/user/login');
            } else if (value) {
                navigate(value);
            }
            // setOpen(null);
        } catch (err) {
            customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
        }
    };

    const openGuide = () => {
        if (currentStep !== 0) setCurrentStep(0);
        setIsOpen(true);
    };


    const [isRepOutside, setIsRepOutside] = useState({});

    useEffect(() => {
        const value = JSON.parse(localStorage.getItem('isRepOutside')) || {};
        setIsRepOutside(value);
    }, []);

    const openRepDashboard = () => {
        const { status, url, token } = isRepOutside;
        console.log(`${url}?token=${token}`);

        if (status) window.open(`${url}?token=${token}`, '_blank');
    };

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
                <Link to={`/${userRouteName}/dashboard`} component={RouterLink} sx={{ display: 'contents' }}>
                    <Logo disabledLink />
                </Link>
            </Box>
            <Box sx={{ mb: 2.5, mx: 2.5 }}>
                <Link underline="none">
                    <StyledAccount>
                        <div className="d-flex align-items-center w-100">
                            {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
                            {InitialIcon({
                                initials: account.displayName
                                    .split(' ')
                                    .map((i) => i[0].toUpperCase())
                                    .join(''),
                            })}

                            <Box sx={{ ml: 2 }}>
                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }} className="d-flex justify-content-center">
                                    {account.displayName}{' '}
                                    {account.showcaseAccepted === true && (
                                        <Iconify icon="material-symbols:verified" width={25} style={{ color: informaGreen }} />
                                    )}
                                </Typography>

                                {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {account.role}
                                    </Typography> */}
                            </Box>
                        </div>
                        <Button
                            type="button"
                            variant="contained"
                            onClick={() => navigate('/user/myprofile')}
                            className="mx-auto mt-2 mt-md-3 w-100 d-flex justify-content-start"
                        >
                            <span style={{ height: '20px', width: '20px' }} className="me-2">
                                {icon('my-profile-icon')}
                            </span>
                            <span>My Profile</span>
                        </Button>
                    </StyledAccount>
                </Link>
            </Box>
            {/* <NavSection data={navConfig} /> */}
            {/* profile percentage indicator */}
            <CommonCircularProgress/>
            {/* showcase btn */}
            <Box onClick={() => navigate('/user/showcase')}>
                <StyledNavItem
                    className = {pathname.includes('showcase') ? 'active' : ''}
                    sx={{
                        color: '#161521',
                        margin: '0 0.5rem',
                        '&.active': {
                            color: 'black',
                            bgcolor: informaGreen,
                            fontWeight: 'fontWeightBold',
                        },
                        '&:hover': {
                            color: '#0E924D',
                        },
                    }}
                >
                    <StyledNavItemIcon>
                        <Iconify icon="ion:cube-sharp" width={25} />
                    </StyledNavItemIcon>
                    <ListItemText disableTypography primary={'Seller Profile'} />
                </StyledNavItem>
            </Box>
            {/* dashboard */}
            <Box onClick={() => navigate('/user/dashboard')}>
                <StyledNavItem
                    className = {pathname.includes('dashboard') ? 'active' : ''}
                    sx={{
                        color: '#161521',
                        margin: '0 0.5rem',
                        '&.active': {
                            color: 'black',
                            bgcolor: informaGreen,
                            fontWeight: 'fontWeightBold',
                        },
                        '&:hover': {
                            color: '#0E924D',
                        },
                    }}
                >
                    <StyledNavItemIcon>
                        <Iconify icon="carbon:dashboard" width={25} />
                    </StyledNavItemIcon>
                    <ListItemText disableTypography primary={'Analytics'} />
                </StyledNavItem>
            </Box>

            {isRepOutside?.status && (
                <Box onClick={openRepDashboard}>
                    <StyledNavItem
                        sx={{
                            color: '#161521',
                            margin: '0 0.5rem',
                            '&.active': {
                                color: 'black',
                                bgcolor: informaGreen,
                                fontWeight: 'fontWeightBold',
                            },
                            '&:hover': {
                                color: '#0E924D',
                            },
                        }}
                    >
                        <StyledNavItemIcon>{icon('Meeting')}</StyledNavItemIcon>
                        <ListItemText disableTypography primary={'Meetings & Chat'} />
                    </StyledNavItem>
                </Box>
            )}
            {/* webinar login btn */}
            <WebinarNavButton />
            <hr />
            <Box onClick={openGuide}>
                <StyledNavItem
                    sx={{
                        color: '#161521',
                        margin: '0 0.5rem',
                        '&.active': {
                            color: 'black',
                            bgcolor: informaGreen,
                            fontWeight: 'fontWeightBold',
                        },
                        '&:hover': {
                            color: '#0E924D',
                        },
                    }}
                >
                    <StyledNavItemIcon>
                        <Iconify icon="mdi:question-mark-circle" width={25} />
                    </StyledNavItemIcon>
                    <ListItemText disableTypography primary={'User Guide'} />
                </StyledNavItem>
            </Box>
            <HelpNavButton />
            <Box onClick={() => handleClose('logout')}>
                <StyledNavItem
                    sx={{
                        color: '#161521',
                        margin: '0 0.5rem',
                        '&.active': {
                            color: 'black',
                            bgcolor: informaGreen,
                            fontWeight: 'fontWeightBold',
                        },
                        '&:hover': {
                            color: '#0E924D',
                        },
                    }}
                >
                    <StyledNavItemIcon>{icon('logout')}</StyledNavItemIcon>
                    <ListItemText disableTypography primary={'Logout'} />
                </StyledNavItem>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV_WIDTH },
            }}
        >
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}

InitialIcon.propTypes = {
    initials: PropTypes.string,
};

function InitialIcon({ initials }) {
    return (
        <div
            style={{
                // backgroundColor: '#dedede',
                // color: 'black',
                backgroundColor: informaGreen,
                color: 'white',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                borderRadius: 30,
                width: 40,
                height: 40,
                minWidth: 40,
                minHeight: 40,
            }}
        >
            <span style={{ fontSize: 18 }}>{initials}</span>
        </div>
    );
}
